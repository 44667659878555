<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Kategorie bearbeiten">
      <download-category-form
        :loading="loading"
        :category="category"
        :form-submitted="saveCategory"
        mode="edit"
      ></download-category-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import DownloadCategoryForm from "@/components/forms/DownloadCategoryForm"
import DownloadCategoriesRepository from "@/repositories/download_categories_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Kategorie bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    DownloadCategoryForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      category: {}
    }
  },
  async created() {
    try {
      this.category = await DownloadCategoriesRepository.get(
        this.$route.params.id
      )
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveCategory(category) {
      await DownloadCategoriesRepository.update(category.id, category)
      this.$router.push({ name: "DownloadCategoriesPage" })
    }
  }
}
</script>
